/* eslint-disable prettier/prettier */
/* import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import { firebaseConfig } from 'constants/defaultValues';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();

export { auth, database };
 */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

import { firebaseConfig } from "constants/defaultValues";

// Inicializa Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Obtiene instancias de Auth y Database
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);

export { auth, database };
